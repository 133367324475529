import TransText from 'next-translate/TransText';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import { Button } from '@components/ui/Button';
import DeskoptBr from '@components/ui/DeskoptBr';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';

import styles from './MoreFeatures.module.css';
import { ReactComponent as Box1SVG } from './assets/box1.svg';
import { ReactComponent as Box2SVG } from './assets/box2.svg';
import { ReactComponent as Box3SVG } from './assets/box3.svg';
import { ReactComponent as Box4SVG } from './assets/box4.svg';
import { ReactComponent as Box5SVG } from './assets/box5.svg';
import { ReactComponent as Box6SVG } from './assets/box6.svg';
import { ReactComponent as Box7SVG } from './assets/box7.svg';
import { ReactComponent as Box8SVG } from './assets/box8.svg';

const ns = 'pages/referral-program/refer-a-friend/index';
function MoreFeatures() {
    const { t } = useTranslation(ns);

    return (
        <div className={styles.moreFeatures}>
            <h2 className={styles.header}>
                <TransText
                    text={t('featuresTitle').replaceAll(' class="gr-mark"', '')}
                    components={{
                        mark: <Mark textColor="black" />,
                        br: <DeskoptBr />,
                    }}
                />
            </h2>
            <ul className={styles.list}>
                <li>
                    <figure>
                        <Box1SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle1')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText1"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box2SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle2')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText2"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box3SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle3')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText3"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box4SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle4')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText4"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box5SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle5')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText5"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box6SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle6')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText6"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box7SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle7')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText7"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
                <li>
                    <figure>
                        <Box8SVG />
                    </figure>
                    <h3>{t('featuresBoxTitle8')}</h3>
                    <p>
                        <Trans
                            i18nKey="featuresBoxText8"
                            components={{
                                br: <br />,
                            }}
                            ns={ns}
                        />
                    </p>
                </li>
            </ul>
            <Button
                label={t('featuresBtn')}
                color="yellow"
                customLink={<CustomLink href={{ pathname: '/features' }} />}
            />
        </div>
    );
}

export default MoreFeatures;
