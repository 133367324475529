import TransText from 'next-translate/TransText';

import Carousel from '@components/global/Carousel';
import Testimonial from '@components/global/Testimonial';
import DeskoptBr from '@components/ui/DeskoptBr';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';

import styles from './Customers.module.css';
import person1PNG from './assets/person1.png';
import person1PNG2x from './assets/person1@2x.png';
import person2PNG from './assets/person2.png';
import person2PNG2x from './assets/person2@2x.png';
import person3PNG from './assets/person3.png';
import person3PNG2x from './assets/person3@2x.png';

const ns = 'pages/referral-program/refer-a-friend/index';
function Earnings() {
    const { t } = useTranslation(ns);

    return (
        <div className={styles.customers}>
            <h2 className={styles.header}>
                <TransText
                    text={t('customersTitle').replaceAll(' class="gr-mark"', '')}
                    components={{
                        mark: <Mark />,
                        br: <DeskoptBr />,
                    }}
                />
            </h2>

            <Carousel>
                <Testimonial
                    className={styles.testimonial}
                    name={t('customersPerson2')}
                    text={t('customersText2')}
                    textUrl={t('customersSlideView')}
                    version
                    img={
                        <picture>
                            <img
                                src={person2PNG.src}
                                srcSet={`${person2PNG2x.src} 2x`}
                                alt=""
                            />
                        </picture>
                    }
                />
                <Testimonial
                    className={styles.testimonial}
                    name={t('customersPerson3')}
                    text={t('customersText3')}
                    textUrl={t('customersSlideView')}
                    version
                    img={
                        <picture>
                            <img
                                src={person3PNG.src}
                                srcSet={`${person3PNG2x.src} 2x`}
                                alt=""
                            />
                        </picture>
                    }
                />
                <Testimonial
                    className={styles.testimonial}
                    name={t('customersPerson1')}
                    text={t('customersText1')}
                    textUrl={t('customersSlideView')}
                    secondVersion
                    img={
                        <picture>
                            <img
                                src={person1PNG.src}
                                srcSet={`${person1PNG2x.src} 2x`}
                                alt=""
                            />
                        </picture>
                    }
                />
            </Carousel>
        </div>
    );
}

export default Earnings;
