import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import { Button } from '@components/ui/Button';
import DeskoptBr from '@components/ui/DeskoptBr';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteStartFree } from '@static_components/laravelLinks';

import styles from './Earnings.module.css';

const ns = 'pages/referral-program/refer-a-friend/index';
function Earnings() {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <div className={styles.earnings}>
            <h2 className={styles.header}>
                <TransText
                    text={t('earningsTitle').replaceAll(' class="gr-mark"', '')}
                    components={{
                        mark: <Mark textColor="black" />,
                        br: <DeskoptBr />,
                    }}
                />
            </h2>
            <ul className={styles.list}>
                <li>
                    <h3>{t('earningsBoxTitle1')}</h3>
                    <p>{t('earningsBoxText1')}</p>
                </li>
                <li>
                    <h3>{t('earningsBoxTitle2')}</h3>
                    <p>{t('earningsBoxText2')}</p>
                </li>
                <li>
                    <h3>{t('earningsBoxTitle3')}</h3>
                    <p>{t('earningsBoxText3')}</p>
                </li>
                <li>
                    <h3>{t('earningsBoxTitle4')}</h3>
                    <p>{t('earningsBoxText4')}</p>
                </li>
            </ul>
            <Button
                label={t('earningsBtn')}
                color="yellow"
                href={laravelRouteStartFree(locale)}
            />
        </div>
    );
}

export default Earnings;
