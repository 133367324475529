import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import {
    laravelRouteFeaturesConversionFunnel,
    laravelRouteFeaturesMarketingAutomation,
    laravelRouteFeaturesWebinarSoftware,
    laravelRouteFeaturesWebsiteBuilder,
} from '@static_components/laravelLinks';

import styles from './Features.module.css';
import box1AVIF1x from './assets/box1.avif';
import box1PNG1x from './assets/box1.png';
import { ReactComponent as Box1SVG } from './assets/box1.svg';
import box1AVIF2x from './assets/box1@2x.avif';
import box1PNG2x from './assets/box1@2x.png';
import box2AVIF1x from './assets/box2.avif';
import box2PNG1x from './assets/box2.png';
import { ReactComponent as Box2SVG } from './assets/box2.svg';
import box2AVIF2x from './assets/box2@2x.avif';
import box2PNG2x from './assets/box2@2x.png';
import box3AVIF1x from './assets/box3.avif';
import box3PNG1x from './assets/box3.png';
import { ReactComponent as Box3SVG } from './assets/box3.svg';
import box3AVIF2x from './assets/box3@2x.avif';
import box3PNG2x from './assets/box3@2x.png';
import box4AVIF1x from './assets/box4.avif';
import box4PNG1x from './assets/box4.png';
import { ReactComponent as Box4SVG } from './assets/box4.svg';
import box4AVIF2x from './assets/box4@2x.avif';
import box4PNG2x from './assets/box4@2x.png';
import box5AVIF1x from './assets/box5.avif';
import box5PNG1x from './assets/box5.png';
import { ReactComponent as Box5SVG } from './assets/box5.svg';
import box5AVIF2x from './assets/box5@2x.avif';
import box5PNG2x from './assets/box5@2x.png';
import Feature from './components/Feature';

const ns = 'pages/referral-program/refer-a-friend/index';

function Features() {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <ul className={styles.features}>
            <li>
                <Feature
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${box1AVIF1x.src} 1x, ${box1AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${box1PNG1x.src} 1x, ${box1PNG2x.src} 2x`} />

                            <img
                                alt=""
                                width={box1PNG1x.width}
                                height={box1PNG1x.height}
                                loading="lazy"
                                src={box1PNG1x.src}
                            />
                        </picture>
                    }
                    icon={<Box1SVG />}
                    title={t('topBoxTitle1')}
                    description={t('topBoxText1')}
                    link={(className) => (
                        <a
                            href={laravelRouteFeaturesMarketingAutomation(locale)}
                            className={className}
                        >
                            {t('topBoxLink1')}
                        </a>
                    )}
                />
            </li>
            <li>
                <Feature
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${box2AVIF1x.src} 1x, ${box2AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${box2PNG1x.src} 1x, ${box2PNG2x.src} 2x`} />

                            <img
                                alt=""
                                width={box2PNG1x.width}
                                height={box2PNG1x.height}
                                loading="lazy"
                                src={box2PNG1x.src}
                            />
                        </picture>
                    }
                    icon={<Box2SVG />}
                    title={t('topBoxTitle2')}
                    description={t('topBoxText2')}
                    link={(className) => (
                        <CustomLink
                            className={className}
                            href={{ pathname: '/features/email-marketing' }}
                        >
                            {t('topBoxLink2')}
                        </CustomLink>
                    )}
                />
            </li>
            <li>
                <Feature
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${box3AVIF1x.src} 1x, ${box3AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${box3PNG1x.src} 1x, ${box3PNG2x.src} 2x`} />

                            <img
                                alt=""
                                width={box3PNG1x.width}
                                height={box3PNG1x.height}
                                loading="lazy"
                                src={box3PNG1x.src}
                            />
                        </picture>
                    }
                    icon={<Box3SVG />}
                    title={t('topBoxTitle3')}
                    description={t('topBoxText3')}
                    link={(className) => (
                        <a
                            href={laravelRouteFeaturesWebsiteBuilder(locale)}
                            className={className}
                        >
                            {t('topBoxLink3')}
                        </a>
                    )}
                />
            </li>
            <li>
                <Feature
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${box4AVIF1x.src} 1x, ${box4AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${box4PNG1x.src} 1x, ${box4PNG2x.src} 2x`} />

                            <img
                                alt=""
                                width={box4PNG1x.width}
                                height={box4PNG1x.height}
                                loading="lazy"
                                src={box4PNG1x.src}
                            />
                        </picture>
                    }
                    icon={<Box4SVG />}
                    title={t('topBoxTitle4')}
                    description={t('topBoxText4')}
                    link={(className) => (
                        <a
                            href={laravelRouteFeaturesConversionFunnel(locale)}
                            className={className}
                        >
                            {t('topBoxLink4')}
                        </a>
                    )}
                />
            </li>
            <li>
                <Feature
                    picture={
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${box5AVIF1x.src} 1x, ${box5AVIF2x.src} 2x`}
                            />
                            <source srcSet={`${box5PNG1x.src} 1x, ${box5PNG2x.src} 2x`} />

                            <img
                                alt=""
                                width={box5PNG1x.width}
                                height={box5PNG1x.height}
                                loading="lazy"
                                src={box5PNG1x.src}
                            />
                        </picture>
                    }
                    icon={<Box5SVG />}
                    title={t('topBoxTitle5')}
                    description={t('topBoxText5')}
                    link={(className) => (
                        <a
                            href={laravelRouteFeaturesWebinarSoftware(locale)}
                            className={className}
                        >
                            {t('topBoxLink5')}
                        </a>
                    )}
                />
            </li>
        </ul>
    );
}

export default Features;
